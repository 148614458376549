.check-button{
    border: 2px solid white;
    text-align: center;
    font-size: 200%;
    margin-bottom: 28px;
}

.check-button:hover{
    cursor: pointer;
    border : 2px solid #EFD95B;
    color: #EFD95B;
}